<template>
  <v-container fluid class="pa-0 ma-0" style="font-size: 0px">
    <hero></hero>
    <contact-form></contact-form>
    <maps></maps>
  </v-container>
</template>

<script>
import ContactForm from '@/components/contato/Form.vue'
import Hero from '@/components/contato/Hero.vue'
import Maps from '@/components/contato/Maps.vue'

export default {
  components: {
    ContactForm,
    Hero,
    Maps,
  },
}
</script>
