<template>
  <v-content class="pa-0">
    <v-responsive class="mx-auto" width="100%" max-width="1200">
      <v-row justify="center" align="center">
        <v-col cols="12" md="8">
          <v-responsive width="100%" max-width="500" class="px-4 px-sm-0 mx-auto">
            <div class="text-h4 font-weight-bold primary--text">Aguardamos seu Contato</div>
            <div class="body-1 grey--text">
              Preencha o formulário abaixo ou entre em contato conosco por telefone ou e-mail.
            </div>
          </v-responsive>
          <iframe
            class="contact-frame"
            src="https://www.provaja.com.br/contato"
            scrolling="no"
          ></iframe>
        </v-col>
        <v-col cols="12" md="4" class="px-4 px-sm-0">
          <div class="text-h6 font-weight-bold primary--text">Localização:</div>
          <div class="body-1 grey--text">Rua Cantagalo, 692 - 13º andar</div>
          <div class="body-1 grey--text">Tatuapé - São Paulo - SP</div>
          <div class="body-1 grey--text">CEP: 03319-000</div>

          <div class="text-h6 font-weight-bold primary--text mt-6">Telefone:</div>
          <div class="body-1 grey--text">+55 (11) 2091-1206</div>

          <v-btn
            flat
            href="https://api.whatsapp.com/send?phone=+5511999957055"
            target="_blank"
            color="#128c7e"
            class="mt-6"
            dark
          >
            <v-icon class="mr-2">mdi-whatsapp</v-icon> Whatsapp
          </v-btn>

          <div class="text-h6 font-weight-bold primary--text mt-6">E-mail:</div>
          <div class="body-1">
            <a class="email-link" href="mailto:comercial@piscoidc.com.br"
              >comercial@piscoidc.com.br</a
            >
          </div>
        </v-col>
      </v-row>
    </v-responsive>
  </v-content>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.contact-frame {
  display: block;
  margin: auto;
  outline: none;
  border: none;
  appearance: none;
  min-height: 520px;
  width: 100%;
}

.email-link {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  text-decoration: none;
  transition: color 0.25s ease-in-out;

  &:hover {
    color: var(--v-accent-base);
  }
}
</style>
